/**
 * Handles showing of missing calibrations during exams in which
 * prior calibrations are required before running.
 */
import React from "react";
import Controls from "../controls/Controls";
import ItemCalibration from "./ItemCalibration";
import { Typography } from "@mui/material";

export const MissingCalibrations = (props) => {
  const { cable, coupler, parameters, test, setCable, setCoupler } = props;
  return (
    <div>
      {cable ? (
        <>
          <Typography variant="h6" color="error">
            Calibrate cable before burn in.
          </Typography>
          <ItemCalibration
            item={cable}
            name={"Cable"}
            test={test}
            initialCalibrationValues={parameters}
          />
          <Controls.Button text="Close Calibration" onClick={setCable} />
        </>
      ) : null}

      {!cable && coupler ? (
        <>
          <Typography variant="h6" color="error">
            Calibrate coupler before burn in.
          </Typography>
          <ItemCalibration
            item={coupler}
            name={"Coupler"}
            test={test}
            initialCalibrationValues={parameters}
          />
          <Controls.Button text="Close Calibration" onClick={setCoupler} />
        </>
      ) : null}
    </div>
  );
};
