import React from "react";
import { Grid, Paper, Typography } from "@mui/material";
import Controls from "../controls/Controls";
import MissingCalibrationForm from "../Form/MissingCalbrationForm";

/**
 * Constants for calibration forms and tables.
 */
export const calibrationTextFields = [
  { id: "lowFrequency", label: "Low Freq.|GHz" },
  { id: "highFrequency", label: "High Freq.|GHz" },
  { id: "freqStep", label: "Freq. Step|GHz" },
  { id: "inputPower", label: "Input Power|dBm" },
  {
    id: "readDelay",
    label: "Read Delay|s",
    toolTip: "Delay between each read.",
  },
];

export const calibrationHeadCells = [
  {
    id: "serial",
    label: "Serial Id",
    showOnSwipe: true,
    displayOnMobile: true,
  },
  { id: "input_power", label: "Input Power" },
  { id: "frequency_range", label: "Frequency Range", displayOnMobile: true },
  { id: "frequency_step", label: "Frequency Step" },
  { id: "date_started", label: "Calibration Date" },
  {
    id: "actions",
    label: "Actions",
    disableSorting: true,
    showOnSwipe: true,
    hideUntilSwipe: true,
    displayOnMobile: true,
  },
];

export const itemCalibrationHeadCells = [
  {
    id: "date_started",
    label: "Date Done",
    showOnSwipe: true,
    displayOnMobile: true,
  },
  { id: "input_power", label: "Input Power" },
  { id: "frequency_range", label: "Frequency Range", displayOnMobile: true },
  { id: "frequency_step", label: "Frequency Step" },
  {
    id: "actions",
    label: "Actions",
    disableSorting: true,
    showOnSwipe: true,
    hideUntilSwipe: true,
    displayOnMobile: true,
  },
];

export const cableCalibrationCsvHeaders = [
  { label: "Frequency", key: "frequency" },
  { label: "Attenuation", key: "attenuation" },
  { label: "Read Value", key: "readValue" },
];

export const couplerCalibrationCsvHeaders = [
  { label: "Frequency", key: "frequency" },
  { label: "Attenuation", key: "attenuation" },
  { label: "CableAttenuation", key: "cableAtten" },
  { label: "Read Value", key: "readValue" },
];

export const pastCalibrationResultsColumns = [
  {
    width: 80,
    label: "Freq",
    dataKey: "frequency",
    numeric: true,
  },
  {
    width: 80,
    label: "Atten",
    dataKey: "attenuation",
    numeric: true,
  },
];

export const calibrationResultsColumns = [
  {
    width: 80,
    label: "Freq",
    dataKey: "frequency",
    numeric: true,
  },
  {
    width: 80,
    label: "Atten",
    dataKey: "attenuation",
    numeric: true,
  },
  {
    width: 80,
    label: "Prev",
    dataKey: "previous",
    numeric: true,
  },
  {
    width: 80,
    label: "Diff",
    dataKey: "difference",
    numeric: true,
  },
];

export const couplerCalibrationForm = {
  initialValues: {
    stand: "",
    cable: "",
    coupler: "",
    powerHead: "",
    lowFrequency: "",
    highFrequency: "",
    freqStep: "",
    inputPower: "",
    readDelay: 1,
    outputOffset: 0,
  },
  formLayout: [
    {
      type: "select",
      menuItems: [],
      menuItemName: "stand",
      defaultItem: null,
      menuItemTextValue: "stand",
      label: "Stand",
    },
    {
      type: "select",
      menuItems: [],
      menuItemName: "cable",
      defaultItem: null,
      menuItemTextValue: "serial",
      label: "Cable",
    },
    {
      type: "select",
      menuItems: [],
      menuItemName: "coupler",
      defaultItem: null,
      disabled: true,
      menuItemTextValue: "serial",
      label: "Coupler",
    },
    {
      type: "select",
      menuItems: [],
      menuItemName: "powerHead",
      defaultItem: null,
      menuItemTextValue: "serial",
      label: "Power Head",
    },
    {
      type: "textfields",
      fields: calibrationTextFields,
      fieldsName: "Calibration Text Fields",
    },
    {
      type: "slider",
      title: "Output Offset",
      name: "outputOffset",
      value: 0,
    },
    {
      type: "buttons",
    },
  ],
  storedValues: null,
};

export const cableCalibrationForm = {
  initialValues: {
    stand: "",
    cable: "",
    lowFrequency: "",
    highFrequency: "",
    freqStep: "",
    inputPower: "",
    readDelay: 1,
    outputOffset: 0,
  },
  formLayout: [
    {
      type: "select",
      menuItems: [],
      menuItemName: "stand",
      defaultItem: null,
      menuItemTextValue: "stand",
      label: "Stand",
    },
    {
      type: "select",
      menuItems: [],
      menuItemName: "cable",
      defaultItem: null,
      disabled: true,
      menuItemTextValue: "serial",
      label: "Cable",
    },
    {
      type: "textfields",
      fields: calibrationTextFields,
      fieldsName: "Calibration Text Fields",
    },
    {
      type: "slider",
      title: "Output Offset",
      name: "outputOffset",
      value: 0,
    },
    {
      type: "buttons",
    },
  ],
  storedValues: null,
};

export const formatCalResults = (results) => {
  let calibrationData = [];
  results.forEach((result) => {
    calibrationData.push({
      index: result.index,
      frequency: parseFloat(result.frequency),
      attenuation: parseFloat(result.attenuation),
      readValue: parseFloat(result.read_value),
      cableAtten: result.cable_offset ? result.cable_offset : null,
      date: result.date ? result.date : null,
    });
  });
  return calibrationData;
};
export const printCalMetaData = (data) => (
  <>
    {data ? (
      <>
        <Grid>
          <Grid>
            <Typography variant="subtitle1">
              Date Started: {new Date(data.date_started).toLocaleString()}
            </Typography>
          </Grid>
          <Grid>
            <Typography variant="subtitle1">
              Frequency Range:{" "}
              {
                data.calibration_parameter.frequency_parameter.frequency_range
                  .low_frequency
              }
              -
              {
                data.calibration_parameter.frequency_parameter.frequency_range
                  .high_frequency
              }
              GHz Frequency Step:{" "}
              {data.calibration_parameter.frequency_parameter.frequency_step}
              GHz
            </Typography>
          </Grid>
          <Typography variant="subtitle1">
            Input Power: {data.calibration_parameter.input_power}dBm Input
            Offset: {data.calibration_parameter.input_padding}dBm Output Offset:{" "}
            {data.calibration_parameter.output_padding}dBm
          </Typography>
        </Grid>
      </>
    ) : null}
  </>
);

export const heading = (name, item, data) => (
  <>
    {item ? (
      <Grid container justifyContent="center" alignContent="center">
        <Paper elevation={3}>
          <Typography variant="h5">
            {name} Calibration: {item.name}
          </Typography>
          <Typography variant="h6">Serial Number: {item.serial}</Typography>
          {printCalMetaData(data)}
        </Paper>
      </Grid>
    ) : null}
  </>
);

export const popups = (
  openPopup,
  setOpenPopup,
  missingCalibrations,
  setInProcess
) => (
  <>
    <Controls.Popup
      title={`Uncalibrated Cable Frequencies`}
      openPopup={openPopup}
      setOpenPopup={setOpenPopup}
    >
      <MissingCalibrationForm
        missingCalibrations={missingCalibrations}
        setInProcess={setInProcess}
        closePopup={setOpenPopup}
      />
    </Controls.Popup>
  </>
);
