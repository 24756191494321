/**
 * Styling of the layout of the webpage.
 */
import React, { useEffect } from "react";
import Navbar from "../components/Navbar/Navbar";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import { connect } from "react-redux";
import { checkAuthenticated, load_user } from "../actions/auth";

function Copyright() {
  return (
    <Grid container justifyContent="center" alignContent="flex-end">
      <Typography variant="body2" color="textSecondary" align="center">
        {"© "}
        {new Date().getFullYear()}{" "}
        <Link color="inherit" href="/">
          Quarterwave Corp
        </Link>
        {". "}
        {"All rights reserved."}
      </Typography>
    </Grid>
  );
}

const Layout = (props) => {
  const {
    toggleColorMode,
    mode,
    message,
    changeMessage,
    clearMessage,
    checkAuthenticated,
    load_user,
    user,
    children,
  } = props;
  useEffect(() => {
    checkAuthenticated();
    load_user();
  }, [checkAuthenticated, load_user]);

  return (
    <>
      <Navbar
        mode={mode}
        toggleColorMode={toggleColorMode}
        message={message}
        changeMessage={changeMessage}
        clearMessage={clearMessage}
        user={user}
      >
        {children}
        <Copyright />
      </Navbar>
    </>
  );
};

export default connect(null, { checkAuthenticated, load_user })(Layout);
