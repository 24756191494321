/**
 * Component for amplifier status
 */
import Grid from "@mui/material/Grid";
import React, { useRef, useState } from "react";
import Gauge from "../Status/Gauge";
import { example_status } from "../Status/ExampleStatus";
import Chips from "../Status/Chips";
import Typography from "@mui/material/Typography";
import EmojiFlagsIcon from "@mui/icons-material/EmojiFlags";
// import HomeWorkIcon from "@mui/icons-material/HomeWork";
import HistoryIcon from "@mui/icons-material/History";
import SpeedIcon from "@mui/icons-material/Speed";
import Controls from "../controls/Controls";
import MuiPaper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import Chart from "../Chart/Chart";
import { StatusLines, statusCheckboxes } from "../Chart/ChartConstants";
import { convertTime, nestedCopy } from "../controls/Constants";
import { IconButton } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";

export const Paper = styled(MuiPaper)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

const convertStatus = (status) => {
  let statState = {};
  for (const anaVal of status.status.analog_values) {
    statState[anaVal.name] = false;
  }
  for (const state of status.status.states) {
    statState[state.name] = false;
  }
  return statState;
};

export default function AmplifierStatus(props) {
  const { unitStatus, statusHistory } = props;
  const [view, setView] = useState("analog");
  const [statusState, setStatusState] = useState(convertStatus(unitStatus));
  let statusLimits = useRef({});
  const handleViewChange = (event, newView) => {
    if (newView && view !== newView) setView(newView);
  };

  const handleStatusChange = (key) => {
    setStatusState({ ...statusState, [key]: !statusState[key] });
  };

  let analogValues = unitStatus
    ? unitStatus.status.analog_values
    : example_status.analog_values;
  let states = unitStatus ? unitStatus.status.states : example_status.states;
  let buttons = [
    // { text: "main", icon: <HomeWorkIcon /> },
    { text: "states", icon: <EmojiFlagsIcon /> },
    { text: "analog", icon: <SpeedIcon /> },
    { text: "history", icon: <HistoryIcon /> },
  ];

  const categorizeStatus = (values) => {
    let categories = [];
    // let categories = {
    //   lvps: {
    //     name: "LVPS Voltages",
    //     keys: ["Vrfr", "Vrfp", "Vtp1", "Vmr"],
    //     values: {},
    //   },
    //   high: {
    //     name: "High Voltages",
    //     keys: ["Vcol", "Vbdy", "Vcl2", "Vcl2", "Icol", "Icat"],
    //     values: {},
    //   },
    //   fdkc: {
    //     name: "FDK C Voltages",
    //     keys: ["Vgri", "Vdrv", "Vbia"],
    //     values: {},
    //   },
    //   heater: {
    //     name: "Heater",
    //     keys: ["Ihtr", "Vhtr"],
    //     values: {},
    //   },
    //   currents: {
    //     name: "Currents",
    //     keys: ["Icol", "Igri", "Icat"],
    //     values: {},
    //   },
    //   rfc: {
    //     name: "RFC Pulses",
    //     keys: ["Pprf", "Lprf", "Dprf"],
    //     values: {},
    //   },
    //   main: {
    //     name: "Main Pulses",
    //     keys: ["Ppin", "Lpin", "Dpin"],
    //     values: {},
    //   },
    //   temps: {
    //     name: "Temperatures",
    //     keys: [],
    //     values: {},
    //   },
    //   unitless: {
    //     name: "Unitless",
    //     keys: [],
    //     values: {},
    //   },
    //   voltages: {
    //     name: "Voltages",
    //     keys: [],
    //     values: {},
    //   },
    // };
    let categorizedStatus = {};
    for (let value in values) {
      if (values[value][0].unit === " " || values[value][0].unit === "") {
        if (categorizeStatus.etc)
          categorizedStatus.etc.push({ [value]: values[value] });
        else categorizedStatus["etc"] = [{ [value]: values[value] }];
      } else {
        if (categories.includes(values[value][0].unit)) {
          categorizedStatus[values[value][0].unit].push({
            [value]: values[value],
          });
        } else {
          categories.push(values[value][0].unit);
          categorizedStatus[values[value][0].unit] = [
            { [value]: values[value] },
          ];
        }
      }
    }
    return categorizedStatus;
  };

  const parseHistory = () => {
    let analogHistory = {};
    let stateHistory = {};
    if (statusHistory.length) {
      statusHistory.forEach(function(status, index) {
        for (const analogValues of status.status.analog_values) {
          if (!analogHistory[analogValues.name])
            analogHistory[analogValues.name] = [];
          analogHistory[analogValues.name].push({
            ...analogValues,
            low: parseFloat(analogValues.low).toFixed(2),
            high: parseFloat(analogValues.high).toFixed(2),
            act: analogValues.act
              ? parseFloat(analogValues.act).toFixed(2)
              : !isNaN(analogValues.act)
              ? parseFloat(analogValues.act)
              : null,
            index: index,
            timestamp: parseFloat(status.timestamp),
          });
          statusLimits.current[analogValues.name] = {
            low: parseFloat(analogValues.low).toFixed(2),
            high: parseFloat(analogValues.high).toFixed(2),
          };
        }
        for (const stateValues of status.status.states) {
          if (!stateHistory[stateValues.name])
            stateHistory[stateValues.name] = [];
          stateHistory[stateValues.name].push({
            ...stateValues,
            act: stateValues.act ? 1 : 0,
            index: index,
            timestamp: parseFloat(status.timestamp),
          });
        }
      });
    }
    return [analogHistory, stateHistory];
  };

  const setupData = (category, status) => {
    console.log("ssetup", category, status);
    let chartData = [];
    let firstKey = Object.keys(status)[0];
    for (
      let itr = 0;
      itr < status[firstKey][Object.keys(status[firstKey])[0]].length;
      itr++
    ) {
      let y = {};
      for (let stat of status) {
        let key = Object.keys(stat)[0];
        y[key] = stat[key][itr].act;
        y.timestamp = stat[key][itr].timestamp;
        y.index = itr;
      }
      chartData.push({ ...y });
    }
    // console.log("setupdata", chartData, status);
    return chartData;
  };

  const setupLines = (category, status) => {
    let lines = { ...StatusLines(category) };
    for (let stat of status) {
      let key = Object.keys(stat)[0];
      lines.dataKeys.push(key);
      lines.labels[key] = stat[key][0].full_name;
      var randomColor =
        "#" + ((Math.random() * 0xffffff) << 0).toString(16).padStart(6, "0");
      lines.colors[key] = randomColor;
      lines.units[key] = stat[key][0].unit;
      lines.toolTip.enabled[key] = true;
    }
    // console.log("lines", lines);
    return lines;
  };

  const setupCheckBoxes = (checkBoxes, status) => {
    let checkBoxCopy = nestedCopy(checkBoxes);
    for (let stat of status) {
      let newCheckBox = {};
      let key = Object.keys(stat)[0];
      newCheckBox.id = stat[key][0].name;
      newCheckBox.label = stat[key][0].full_name;
      newCheckBox.active = true;
      checkBoxCopy.push({ ...newCheckBox });
    }
    return checkBoxCopy;
  };

  const renderHistory = () => {
    let [analogHistory, stateHistory] = parseHistory();
    let categorizedStatus = categorizeStatus(analogHistory);
    return (
      <>
        {!analogHistory ? null : (
          <Grid container justifyContent={"center"} alignContent={"center"}>
            {Object.keys(categorizedStatus).map((analog, index) => {
              return (
                <Grid item key={`analog${index}`}>
                  {analog}
                  <IconButton
                    onClick={() => {
                      handleStatusChange(analog);
                    }}
                  >
                    {statusState[analog] ? (
                      <ArrowDropUpIcon />
                    ) : (
                      <ArrowDropDownIcon />
                    )}
                  </IconButton>
                  {statusState[analog] ? (
                    <Chart
                      // width={300}
                      // height={200}
                      disableAux={true}
                      limits={statusLimits.current}
                      chartData={setupData(analog, categorizedStatus[analog])}
                      checkBoxes={setupCheckBoxes(
                        statusCheckboxes,
                        categorizedStatus[analog]
                      )}
                      lines={setupLines(analog, categorizedStatus[analog])}
                    />
                  ) : null}
                </Grid>
              );
            })}
          </Grid>
        )}
      </>
    );
  };

  return (
    <Paper elevation={10}>
      <Controls.ToggleButtons
        value={view}
        onChange={handleViewChange}
        buttons={buttons}
      />
      {unitStatus ? null : (
        <Typography variant="h4">This is DEBUG data.</Typography>
      )}
      <Grid container justifyContent="center">
        {/* {view === "main" ? <Chips chips={mainStates} /> : null} */}
        {view === "states" ? (
          <>
            <Grid container justifyContent="center">
              <Typography variant="h4">{`Last State Query. ${
                unitStatus.timestamp
                  ? `${convertTime(unitStatus.timestamp)} into the exam.`
                  : ""
              }`}</Typography>
            </Grid>
            <Grid container justifyContent="center">
              <Chips chips={states} />
            </Grid>
          </>
        ) : null}
        {view === "analog" ? (
          <>
            <Grid container justifyContent="center">
              <Typography variant="h4">{`Last Status Query. ${
                unitStatus.timestamp
                  ? `${convertTime(unitStatus.timestamp)} into the exam.`
                  : ""
              }`}</Typography>
            </Grid>

            {analogValues.map((status, index) => {
              return (
                <Grid item xs key={`${status.name} + ${index}`}>
                  <Gauge
                    low={parseFloat(status.low)}
                    high={parseFloat(status.high)}
                    value={parseFloat(status.act)}
                    strValue={status.str_act}
                    name={status.name}
                    fullName={status.full_name}
                  />
                </Grid>
              );
            })}
          </>
        ) : null}
      </Grid>
      {view === "history" ? <>{renderHistory()}</> : null}
    </Paper>
  );
}
