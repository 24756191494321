/**
 * Constants for exam tables and forms.
 */
export const compressionExamHeadCells = [
  { id: "amplifier", label: "Amplifier", displayOnMobile: true },
  { id: "power_range", label: "Power Range", displayOnMobile: true },
  { id: "frequency_range", label: "Frequency Range", displayOnMobile: true },
  { id: "frequency_step", label: "Frequency Step", displayOnMobile: false },
  { id: "date_started", label: "Exam Date" },
  {
    id: "actions",
    label: "Actions",
    disableSorting: true,
    showOnSwipe: true,
    hideUntilSwipe: true,
    displayOnMobile: true,
  },
];

export const saturationExamHeadCells = [
  { id: "amplifier", label: "Amplifier", displayOnMobile: true },
  { id: "power_range", label: "Power Range", displayOnMobile: true },
  { id: "frequency_range", label: "Frequency Range", displayOnMobile: true },
  { id: "frequency_step", label: "Frequency Step", displayOnMobile: false },
  { id: "duty_cycle", label: "Duty Cycle", displayOnMobile: true },
  { id: "date_started", label: "Exam Date" },
  { id: "user", label: "User" },
  {
    id: "actions",
    label: "Actions",
    disableSorting: true,
    showOnSwipe: true,
    hideUntilSwipe: true,
    displayOnMobile: true,
  },
];

export const burnInHeadCells = [
  { id: "amplifier", label: "Amplifier", displayOnMobile: true },
  { id: "date_started", label: "Exam Date" },
  { id: "user", label: "User" },
  {
    id: "actions",
    label: "Actions",
    disableSorting: true,
    showOnSwipe: true,
    hideUntilSwipe: true,
    displayOnMobile: true,
  },
];

export const saturationColumns = [
  {
    width: 80,
    label: "Freq",
    dataKey: "frequency",
    numeric: true,
  },
  {
    width: 80,
    label: "Out",
    dataKey: "saturationOutput",
    numeric: true,
  },
  {
    width: 80,
    label: "Spec",
    dataKey: "specifiedOutput",
    numeric: true,
  },
  {
    width: 80,
    label: "Gain",
    dataKey: "gain",
    numeric: true,
  },
];

export const saturationExamTextFields = [
  { id: "lowInput", label: "Lower Power Bound|dBm" },
  { id: "highInput", label: "Upper Power Bound|dBm" },
  { id: "dutyCycle", label: "Duty Cycle|%" },
  { id: "lowFrequency", label: "Lower Frequency Bound|GHz" },
  { id: "highFrequency", label: "Upper Frequency Bound|GHz" },
  { id: "freqStep", label: "Frequency Step|GHz" },
  { id: "readDelay", label: "Read Delay|s" },

  // {
  //   id: "queryDelay",
  //   label: "Unit Queries/Hour",
  //   toolTip: "ex. A value of 60 = 1 unit read every minute.",
  // },
];

export const compressionColumns = [
  {
    width: 80,
    label: "Input",
    dataKey: "inputPower",
    numeric: true,
  },
  {
    width: 80,
    label: "Output",
    dataKey: "outputPower",
    numeric: true,
  },
  {
    width: 80,
    label: "Gain",
    dataKey: "gain",
    numeric: true,
  },
];

export const burnInTextFields = [
  { id: "inputPower", label: "Input Power|dBm" },
  { id: "dutyCycle", label: "Duty Cycle|%" },
  { id: "frequency", label: "Frequency|GHz" },
  // {
  //   id: "queryDelay",
  //   label: "Unit Queries/Hour",
  //   toolTip: "ex. A value of 60 = 1 unit read every minute.",
  // },
  {
    id: "meterDelay",
    label: "Meter Delay|sec(s)",
    toolTip: "Delay between each power meter read.",
  },
  {
    id: "readDelay",
    label: "Read Delay|sec(s)",
    toolTip: "Delay between each read.",
  },
];

export const durationTextFields = [
  { id: "days", label: "Days|day(s)" },
  { id: "hours", label: "Hours|hr(s)" },
  { id: "minutes", label: "Minutes|min(s)" },
  { id: "seconds", label: "Seconds|sec(s)" },
];

export const addCompTextFields = [
  { id: "fineStep", label: "Fine Step|dBm", default: 0.1 },
  { id: "coarseStep", label: "Coarse Step|dBm", default: 1.0 },
  { id: "fineTolerance", label: "Fine Tolerance|%", default: 0.01 },
  { id: "coarseTolerance", label: "Coarse Tolerance|%", default: 0.1 },
];

export const saturationExamForm = {
  initialValues: {
    stand: "",
    amplifier: "",
    coupler: "",
    cable: "",
    powerHead: "",
    lowInput: "",
    highInput: "",
    fineStep: 0.1,
    lowFrequency: "",
    highFrequency: "",
    coarseStep: 1,
    freqStep: "",
    dutyCycle: "",
    coarseTolerance: 10,
    fineTolerance: 1,
    peeks: 3,
    inputOffset: 0,
    outputOffset: 0,
    readDelay: 1,
    queryDelay: 60,
    toggleP1db: false,
    queryUnit: false,
  },
  formLayout: [
    {
      type: "select",
      menuItems: [],
      menuItemName: "stand",
      defaultItem: null,
      menuItemTextValue: "stand",
      label: "Stand",
    },
    {
      type: "select",
      menuItems: [],
      menuItemName: "amplifier",
      defaultItem: null,
      menuItemTextValue: "serial",
      label: "Amplifier",
    },
    {
      type: "select",
      menuItems: [],
      menuItemName: "cable",
      defaultItem: null,
      menuItemTextValue: "serial",
      label: "Cable",
    },
    {
      type: "select",
      menuItems: [],
      menuItemName: "coupler",
      defaultItem: null,
      menuItemTextValue: "serial",
      label: "Coupler",
    },
    {
      type: "select",
      menuItems: [],
      menuItemName: "powerHead",
      defaultItem: null,
      menuItemTextValue: "serial",
      label: "Power Head",
    },
    {
      type: "textfields",
      fields: saturationExamTextFields,
      fieldsName: "CompressionText",
    },
    {
      type: "additional",
      title: "Compression Parameters",
      fields: [
        {
          type: "textfields",
          fields: addCompTextFields,
          fieldsName: "Compression",
        },
      ],
    },
    {
      type: "slider",
      title: "Input Offset",
      name: "inputOffset",
      value: 0,
    },
    {
      type: "slider",
      title: "Output Offset",
      name: "outputOffset",
      value: 0,
    },
    {
      type: "toggle",
      label: "Toggle P1dB Exams",
      name: "toggleP1db",
    },
    {
      type: "toggle",
      label: "Query Unit",
      name: "queryUnit",
    },
    {
      type: "buttons",
    },
  ],
  storedValues: null,
};

export const burnInForm = {
  initialValues: {
    stand: "",
    amplifier: "",
    coupler: "",
    cable: "",
    powerHead: "",
    inputPower: "",
    frequency: "",
    dutyCycle: "",
    inputOffset: 0,
    outputOffset: 0,
    days: 0,
    hours: 0,
    minutes: 10,
    seconds: 0,
    queryDelay: 60,
    meterDelay: 1,
    readDelay: 1,
    queryUnit: false,
  },
  formLayout: [
    {
      type: "select",
      menuItems: [],
      menuItemName: "stand",
      defaultItem: null,
      menuItemTextValue: "stand",
      label: "Stand",
    },
    {
      type: "select",
      menuItems: [],
      menuItemName: "amplifier",
      defaultItem: null,
      menuItemTextValue: "serial",
      label: "Amplifier",
    },
    {
      type: "select",
      menuItems: [],
      menuItemName: "cable",
      defaultItem: null,
      menuItemTextValue: "serial",
      label: "Cable",
    },
    {
      type: "select",
      menuItems: [],
      menuItemName: "coupler",
      defaultItem: null,
      menuItemTextValue: "serial",
      label: "Coupler",
    },
    {
      type: "select",
      menuItems: [],
      menuItemName: "powerHead",
      defaultItem: null,
      menuItemTextValue: "serial",
      label: "Power Head",
    },
    {
      type: "textfields",
      fields: burnInTextFields,
      fieldsName: "BurnInText",
    },
    {
      type: "slider",
      title: "Input Offset",
      name: "inputOffset",
      value: 0,
    },
    {
      type: "slider",
      title: "Output Offset",
      name: "outputOffset",
      value: 0,
    },
    {
      type: "additional",
      title: "Duration Parameters",
      fields: [
        {
          type: "textfields",
          fields: durationTextFields,
          fieldsName: "Duration",
        },
      ],
    },
    {
      type: "toggle",
      label: "Query Unit",
      name: "queryUnit",
    },
    {
      type: "buttons",
    },
  ],
  storedValues: null,
};

export const powerExamHeaders = [
  { label: "Input Power", key: "inputPower" },
  { label: "Output Power", key: "outputPower" },
  { label: "Gain", key: "gain" },
  { label: "Read Value", key: "readValue" },
];

export const satExamHeaders = [
  { label: "Frequency", key: "frequency" },
  { label: "PSat Input", key: "saturationInput" },
  { label: "PSat Output", key: "saturationOutput" },
  { label: "Gain", key: "gain" },
  { label: "P1dB Input", key: "compressionInput" },
  { label: "P1dB Output", key: "compressionOutput" },
];

export const burnInHeaders = [
  // { label: "Frequency", key: "frequency" },
  // { label: "Duty Cycle", key: "dutyCycle" },
  { label: "Timestamp", key: "timestamp" },
  { label: "Input Power", key: "inputPower" },
  { label: "Output Power", key: "outputPower" },
  { label: "Gain", key: "gain" },
  { label: "Read Value", key: "readValue" },
];

export const formatSaturationResults = (results) => {
  let saturationData = [];
  results.forEach((result) => {
    saturationData.push({
      index: result.index,
      frequency: parseFloat(result.frequency),
      saturationInput: parseFloat(result.saturation_input),
      saturationOutput: parseFloat(result.saturation_output),
      compressionInput: parseFloat(result.compression_input),
      compressionOutput: parseFloat(result.compression_output),
      specifiedOutput: parseFloat(result.specified).toFixed(2),
      gain: parseFloat(result.gain),
      // gain: (
      //   parseFloat(result.saturation_output) -
      //   parseFloat(result.saturation_input).toFixed(2)
      // ).toFixed(2),
    });
  });
  return saturationData;
};

export const formatPowerResults = (results) => {
  let tempPowerResults = [];
  results.forEach((result) => {
    tempPowerResults.push({
      index: result.index,
      inputPower: parseFloat(result.input_power),
      outputPower: parseFloat(result.output_power),
      readValue: parseFloat(result.read_value),
      gain: parseFloat(result.gain),
    });
  });
  return tempPowerResults;
};

export const formatBurnResults = (results) => {
  let tempPowerResults = [];
  results.forEach((result) => {
    tempPowerResults.push({
      index: result.index,
      frequency: parseFloat(result.frequency),
      inputPower: parseFloat(result.input_power),
      outputPower: parseFloat(result.output_power),
      readValue: parseFloat(result.read_value),
      specifiedOutput: parseFloat(result.specified_output),
      gain: parseFloat(result.gain),
      timestamp: parseFloat(result.timestamp),
    });
  });
  return tempPowerResults;
};
