/**
 * Class for catalog api calls
 */
import { backendLookup, handleResponse } from "../lookup";
export class CatalogAPIRouter {
  /* Catalog */
  getTestEquip = async () => {
    try {
      const response = await backendLookup("GET", "catalog/");
      return handleResponse(response);
    } catch (err) {
      console.error(err);
      return [];
    }
  };

  getCatalogItems = async (item_type) => {
    try {
      const response = await backendLookup("GET", `${item_type}/`);
      return handleResponse(response);
    } catch (err) {
      console.error(err);
    }
  };

  postCatalogItem = async (item_type, item) => {
    try {
      const response = await backendLookup("POST", `${item_type}/`, item);
      return handleResponse(response);
    } catch (err) {
      console.error(err);
      return [];
    }
  };

  updateCatalogItem = async (item_type, id, item) => {
    try {
      const response = await backendLookup("PUT", `${item_type}/${id}/`, item);
      return handleResponse(response);
    } catch (err) {
      console.error(err);
      return [];
    }
  };

  deleteCatalogItem = async (item_type, id) => {
    try {
      const response = await backendLookup("DELETE", `${item_type}/${id}/`);
      return handleResponse(response);
    } catch (err) {
      console.error(err);
      return [];
    }
  };

  /* Stands */
  getActiveStands = async () => {
    try {
      const response = await backendLookup("GET", `stands/active/`);
      return handleResponse(response);
    } catch (err) {
      console.error(err);
      return [];
    }
  };
}

// const formatParameters = (parameters) => {
//   if (parameters) {
//     let formattedParameter = "";
//     console.log("parm", parameters, Object.keys(parameters));
//     Object.keys(parameters).forEach((parameter, index) => {
//       console.log("param", parameter, parameters[parameter]);
//       formattedParameter += `${index > 1 ? "&" : "?"}${parameter}_${
//         parameters[parameter]
//       }`;
//     });
//     return formattedParameter;
//   }
//   return "";
// };

export const getCatalogItems = async (item_type, additional) => {
  try {
    const response = await backendLookup(
      "GET",
      `${item_type}/`
      // `${item_type}/${formatParameters(additional)}`
    );
    return handleResponse(response);
  } catch (err) {
    console.error(err);
  }
};
