/**
 * Form component for missing calibrations during calibrations.
 */
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import React from "react";
import Controls from "../controls/Controls";

export default function MissingCalibrationForm(props) {
  if (!props.missingCalibrations) return null;
  return (
    <>
      {!props.missingCalibrations.length ? null : (
        <>
          <Typography variant="h5">
            These are the Uncalibrated frequencies for the cable.
          </Typography>
          <Typography variant="h6">
            {props.missingCalibrations.map((frequency) => {
              return `${frequency} `;
            })}
          </Typography>
          <Typography variant="h5">
            Do you want to calibrate these frequencies for the cable?
          </Typography>
          <Grid container justifyContent="center">
            <Controls.Button
              text="Cancel"
              color="secondary"
              variant="contained"
              onClick={props.closePopup}
            />
            <Controls.Button
              text="Calibrate"
              color="primary"
              variant="contained"
              onClick={() => {
                props.closePopup();
              }}
            />
          </Grid>
        </>
      )}
    </>
  );
}
