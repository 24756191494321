/**
 * Handles validation of values in the form.
 */
export const validateValues = (
  values,
  errorMessages,
  setErrorMessages,
  errors,
  setErrors
) => {
  let fieldValues = values;
  let tempMessages = { ...errorMessages };
  for (let message in tempMessages) {
    tempMessages[message] = "";
  }
  let tempErrors = { ...errors };
  for (let error in tempErrors) {
    tempErrors[error] = false;
  }
  for (const name in fieldValues) {
    if (name === "lowInput") {
      if (isNaN(parseFloat(fieldValues[name]))) {
        tempMessages[name] = `Input must be a number.`;
        tempErrors[name] = true;
      }
      if (parseFloat(fieldValues[name]) > parseFloat(fieldValues.highInput)) {
        tempMessages[name] = `Must be smaller than upper bound.`;
        tempErrors[name] = true;
      }
      if (fieldValues.stand && fieldValues.stand !== "") {
        if (
          parseFloat(fieldValues[name]) <
          parseFloat(fieldValues.stand.gener.power_range.low_power)
        ) {
          tempMessages[
            name
          ] = `Must be greater than generator low power bound. ${fieldValues.stand.gener.power_range.low_power} dBm`;
          tempErrors[name] = true;
        }
        if (
          parseFloat(fieldValues[name]) >
          parseFloat(fieldValues.stand.gener.power_range.high_power)
        ) {
          tempMessages[
            name
          ] = `Must be less than generator high power bound. ${fieldValues.stand.gener.power_range.high_power} dBm`;
          tempErrors[name] = true;
        } else {
          tempMessages[name] = "";
          tempErrors[name] = false;
        }
      }
    } else if (name === "highInput") {
      if (isNaN(parseFloat(fieldValues[name]))) {
        tempMessages[name] = `Input must be a number.`;
        tempErrors[name] = true;
      }
      if (parseFloat(fieldValues[name]) < parseFloat(fieldValues.lowInput)) {
        tempMessages[name] = `Must be larger than input lower bound.`;
        tempErrors[name] = true;
      }
      if (fieldValues.stand) {
        if (
          parseFloat(fieldValues[name]) >
          parseFloat(fieldValues.stand.gener.power_range.high_power)
        ) {
          tempMessages[
            name
          ] = `Must be less than generator high power bound. ${fieldValues.stand.gener.power_range.high_power} dBm`;
          tempErrors[name] = true;
        }
        if (
          parseFloat(fieldValues[name]) <
          parseFloat(fieldValues.stand.gener.power_range.low_power)
        ) {
          tempMessages[
            name
          ] = `Must be greater than generator low power bound. ${fieldValues.stand.gener.power_range.low_power} dBm`;
          tempErrors[name] = true;
        } else {
          tempMessages[name] = "";
          tempErrors[name] = false;
        }
      }
    } else if (name === "inputPower") {
      if (fieldValues.stand !== "") {
        if (
          parseFloat(fieldValues[name]) <
          parseFloat(fieldValues.stand.gener.power_range.low_power)
        ) {
          tempMessages[
            name
          ] = `Must be greater than generator low power bound. ${fieldValues.stand.gener.power_range.low_power} dBm`;
          tempErrors[name] = true;
        }
        if (
          parseFloat(fieldValues[name]) >
          parseFloat(fieldValues.stand.gener.power_range.high_power)
        ) {
          tempMessages[
            name
          ] = `Must be less than generator high power bound. ${fieldValues.stand.gener.power_range.high_power} dBm`;
          tempErrors[name] = true;
        } else {
          tempMessages[name] = "";
          tempErrors[name] = false;
        }
        if (isNaN(parseFloat(fieldValues[name]))) {
          tempMessages[name] = `Input must be a number.`;
          tempErrors[name] = true;
        }
      }
    } else if (name === "dutyCycle") {
      if (isNaN(parseFloat(fieldValues[name]))) {
        tempMessages[name] = `Input must be a number.`;
        tempErrors[name] = true;
      } else if (parseFloat(fieldValues[name]) < 0) {
        tempMessages[name] = `Duty cycle must be positive.`;
        tempErrors[name] = true;
      } else if (parseFloat(fieldValues[name]) > 100) {
        tempMessages[name] = `Must be between 0% and 100%.`;
        tempErrors[name] = true;
      }
    } else if (name === "frequency") {
      if (fieldValues.cable !== "") {
        if (
          parseFloat(fieldValues[name]) <
          parseFloat(fieldValues.cable.frequency_range.low_frequency)
        ) {
          tempMessages[
            name
          ] = `Input must higher than cable lower bound. ${fieldValues.cable.frequency_range.low_frequency}`;
          tempErrors[name] = true;
        } else if (
          parseFloat(fieldValues[name]) >
          parseFloat(fieldValues.cable.frequency_range.high_frequency)
        ) {
          tempMessages[
            name
          ] = `Input must lower than cable high bound. ${fieldValues.cable.frequency_range.high_frequency}`;
          tempErrors[name] = true;
        } else {
          tempMessages[name] = "";
          tempErrors[name] = false;
        }
      }
      if (fieldValues.coupler && fieldValues.coupler !== "") {
        if (
          parseFloat(fieldValues[name]) <
          parseFloat(fieldValues.coupler.frequency_range.low_frequency)
        ) {
          tempMessages[
            name
          ] = `Input must higher than coupler lower bound. ${fieldValues.coupler.frequency_range.low_frequency}`;
          tempErrors[name] = true;
        } else if (
          parseFloat(fieldValues[name]) >
          parseFloat(fieldValues.coupler.frequency_range.high_frequency)
        ) {
          tempMessages[
            name
          ] = `Input must lower than coupler high bound. ${fieldValues.coupler.frequency_range.high_frequency}`;
          tempErrors[name] = true;
        } else {
          tempMessages[name] = "";
          tempErrors[name] = false;
        }
      }
      if (fieldValues.amplifier && fieldValues.amplifier !== "") {
        if (
          parseFloat(fieldValues[name]) <
          parseFloat(fieldValues.amplifier.frequency_range.low_frequency)
        ) {
          tempMessages[
            name
          ] = `Input must higher than amplifier lower bound. ${fieldValues.amplifier.frequency_range.low_frequency}`;
          tempErrors[name] = true;
        } else if (
          parseFloat(fieldValues[name]) >
          parseFloat(fieldValues.amplifier.frequency_range.high_frequency)
        ) {
          tempMessages[
            name
          ] = `Input must lower than amplifier high bound. ${fieldValues.amplifier.frequency_range.high_frequency}`;
          tempErrors[name] = true;
        } else {
          tempMessages[name] = "";
          tempErrors[name] = false;
        }
      }
      if (isNaN(parseFloat(fieldValues[name]))) {
        tempMessages[name] = `Input must be a number.`;
        tempErrors[name] = true;
      }
    } else if (name === "lowFrequency") {
      if (isNaN(parseFloat(fieldValues[name]))) {
        tempMessages[name] = `Input must be a number.`;
        tempErrors[name] = true;
      } else if (
        parseFloat(fieldValues[name]) > parseFloat(fieldValues.highFrequency)
      ) {
        tempMessages[
          name
        ] = `Low frequency must be a lower than high frequency.`;
        tempErrors[name] = true;
      }
      if (!tempErrors[name] && fieldValues.cable && fieldValues.cable !== "") {
        if (
          parseFloat(fieldValues[name]) <
          parseFloat(fieldValues.cable.frequency_range.low_frequency)
        ) {
          tempMessages[
            name
          ] = `Input must higher than cable lower bound. ${fieldValues.cable.frequency_range.low_frequency}`;
          tempErrors[name] = true;
        } else if (
          parseFloat(fieldValues[name]) >
          parseFloat(fieldValues.cable.frequency_range.high_frequency)
        ) {
          tempMessages[
            name
          ] = `Input must lower than cable high bound. ${fieldValues.cable.frequency_range.high_frequency}`;
          tempErrors[name] = true;
        } else {
          tempMessages[name] = "";
          tempErrors[name] = false;
        }
      }
      if (
        !tempErrors[name] &&
        fieldValues.coupler &&
        fieldValues.coupler !== ""
      ) {
        if (
          parseFloat(fieldValues[name]) <
          parseFloat(fieldValues.coupler.frequency_range.low_frequency)
        ) {
          tempMessages[
            name
          ] = `Input must higher than coupler lower bound. ${fieldValues.coupler.frequency_range.low_frequency}`;
          tempErrors[name] = true;
        } else if (
          parseFloat(fieldValues[name]) >
          parseFloat(fieldValues.coupler.frequency_range.high_frequency)
        ) {
          tempMessages[
            name
          ] = `Input must lower than coupler high bound. ${fieldValues.coupler.frequency_range.high_frequency}`;
          tempErrors[name] = true;
        }
      }
      if (
        !tempErrors[name] &&
        fieldValues.amplifier &&
        fieldValues.amplifier !== ""
      ) {
        if (
          parseFloat(fieldValues[name]) <
          parseFloat(fieldValues.amplifier.frequency_range.low_frequency)
        ) {
          tempMessages[
            name
          ] = `Input must higher than amplifier lower bound. ${fieldValues.amplifier.frequency_range.low_frequency}`;
          tempErrors[name] = true;
        } else if (
          parseFloat(fieldValues[name]) >
          parseFloat(fieldValues.amplifier.frequency_range.high_frequency)
        ) {
          tempMessages[
            name
          ] = `Input must lower than amplifier high bound. ${fieldValues.amplifier.frequency_range.high_frequency}`;
          tempErrors[name] = true;
        }
      }
    } else if (name === "highFrequency") {
      if (isNaN(parseFloat(fieldValues[name]))) {
        tempMessages[name] = `Input must be a number.`;
        tempErrors[name] = true;
      }
      if (
        parseFloat(fieldValues[name]) < parseFloat(fieldValues.lowFrequency)
      ) {
        tempMessages[
          name
        ] = `High frequency must be a higher than low frequency.`;
        tempErrors[name] = true;
      }
      if (!tempErrors[name] && fieldValues.cable && fieldValues.cable !== "") {
        if (
          parseFloat(fieldValues[name]) <
          parseFloat(fieldValues.cable.frequency_range.low_frequency)
        ) {
          tempMessages[
            name
          ] = `Input must higher than cable lower bound. ${fieldValues.cable.frequency_range.low_frequency}`;
          tempErrors[name] = true;
        } else if (
          parseFloat(fieldValues[name]) >
          parseFloat(fieldValues.cable.frequency_range.high_frequency)
        ) {
          tempMessages[
            name
          ] = `Input must lower than cable high bound. ${fieldValues.cable.frequency_range.high_frequency}`;
          tempErrors[name] = true;
        } else {
          tempMessages[name] = "";
          tempErrors[name] = false;
        }
      }
      if (
        !tempErrors[name] &&
        fieldValues.coupler &&
        fieldValues.coupler !== ""
      ) {
        if (
          parseFloat(fieldValues[name]) <
          parseFloat(fieldValues.coupler.frequency_range.low_frequency)
        ) {
          tempMessages[
            name
          ] = `Input must higher than coupler lower bound. ${fieldValues.coupler.frequency_range.low_frequency}`;
          tempErrors[name] = true;
        } else if (
          parseFloat(fieldValues[name]) >
          parseFloat(fieldValues.coupler.frequency_range.high_frequency)
        ) {
          tempMessages[
            name
          ] = `Input must lower than coupler high bound. ${fieldValues.coupler.frequency_range.high_frequency}`;
          tempErrors[name] = true;
        } else {
          tempMessages[name] = "";
          tempErrors[name] = false;
        }
      }
      if (
        !tempErrors[name] &&
        fieldValues.amplifier &&
        fieldValues.amplifier !== ""
      ) {
        if (
          parseFloat(fieldValues[name]) <
          parseFloat(fieldValues.amplifier.frequency_range.low_frequency)
        ) {
          tempMessages[
            name
          ] = `Input must higher than amplifier lower bound. ${fieldValues.amplifier.frequency_range.low_frequency}`;
          tempErrors[name] = true;
        } else if (
          parseFloat(fieldValues[name]) >
          parseFloat(fieldValues.amplifier.frequency_range.high_frequency)
        ) {
          tempMessages[
            name
          ] = `Input must lower than amplifier high bound. ${fieldValues.amplifier.frequency_range.high_frequency}`;
          tempErrors[name] = true;
        } else {
          tempMessages[name] = "";
          tempErrors[name] = false;
        }
      }
    } else if (name === "freqStep") {
      if (isNaN(parseFloat(fieldValues[name]))) {
        tempMessages[name] = `Input must be a number.`;
        tempErrors[name] = true;
      }
      if (parseFloat(fieldValues[name]) < 0) {
        tempMessages[name] = `Frequency step must be positive.`;
        tempErrors[name] = true;
      }
      if (
        parseFloat(fieldValues.lowFrequency) <
          parseFloat(fieldValues.highFrequency) &&
        parseFloat(fieldValues[name]) === 0
      ) {
        tempMessages[name] = `Frequency step must be greater than 0.`;
        tempErrors[name] = true;
      }
    } else if (
      name === "days" ||
      name === "hours" ||
      name === "minutes" ||
      name === "seconds" ||
      name === "timeSlicing"
    ) {
      if (isNaN(parseInt(fieldValues[name]))) {
        tempMessages[name] = `${name &&
          name[0].toUpperCase() + name.slice(1)} must be a number.`;
        tempErrors[name] = true;
      } else if (parseInt(fieldValues[name]) < 0) {
        tempMessages[name] = `${name &&
          name[0].toUpperCase() + name.slice(1)} must be positive.`;
        tempErrors[name] = true;
      } else {
        tempMessages[name] = "";
        tempErrors[name] = false;
      }
    } else if (name === "timeSlicing") {
      if (isNaN(parseInt(fieldValues[name]))) {
        tempMessages[name] = `Query time must be a number.`;
        tempErrors[name] = true;
      } else if (parseInt(fieldValues[name]) < 0) {
        tempMessages[name] = `Query time must be positive.`;
        tempErrors[name] = true;
      } else if (parseInt(fieldValues[name]) === 0) {
        tempMessages[name] = `Query time must be greater than 0.`;
        tempErrors[name] = true;
      } else {
        tempMessages[name] = "";
        tempErrors[name] = false;
      }
    } else if (name === "ipaddress") {
      tempMessages[name] = "";
      tempErrors[name] = false;
    } else if (name === "port") {
      tempMessages[name] = "";
      tempErrors[name] = false;
    } else {
      tempMessages[name] =
        fieldValues[name] === ""
          ? `${name.charAt(0).toUpperCase() + name.slice(1)} is required`
          : "";
      tempErrors[name] = fieldValues[name] === "" ? true : false;
    }
  }

  setErrorMessages({
    ...tempMessages,
  });
  setErrors({
    ...tempErrors,
  });
  if (fieldValues === values)
    return Object.values(tempMessages).every((x) => x === "");
};
