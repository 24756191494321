/**
 * Buttons for calibration form.
 */
import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Controls from "./Controls";

export default function CalibrationSubmitButtons(props) {
  const { values, validate, handleSubmit } = props;
  const [disableButton, setDisableButton] = useState(false);
  const disableSubmit = () => {
    if (validate(values)) {
      setDisableButton(true);
      handleSubmit(values);
    }
  };

  return (
    <Grid container justifyContent="center">
      {/* <Typography component="div">
        <Typography>Calibration Type</Typography>
        <Grid component="label" container alignItems="center" spacing={1}>
          <Grid item>Automatic</Grid>
          <Grid item>
            <AntSwitch
              checked={manual}
              onChange={toggleManual}
              name="manualCalibration"
            />
          </Grid>
          <Grid item>Manual</Grid>
        </Grid>
      </Typography> */}
      <Controls.Button
        text={disableButton ? "Starting Calibration" : "Calibrate"}
        color="primary"
        variant="contained"
        disabled={disableButton}
        onClick={disableSubmit}
      />
    </Grid>
  );
}
