/**
 * Navbar component allows redirect to other aspects of the website.
 */
import React, { useState } from "react";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { NavbarData } from "./NavbarData";
import Main, { AppBar, DrawerHeader, drawerWidth } from "./NavbarStyles";
import Toolbar from "@mui/material/Toolbar";
import QWaveLogo from "../logo/QWaveLogo";
import { connect } from "react-redux";
import { login, logout } from "../../actions/auth";
import Controls from "../controls/Controls";
import QWaveLogoBig from "../logo/QWaveLogoBig";
import axios from "axios";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { Collapse, Grid, Hidden } from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import LogoutIcon from "@mui/icons-material/Logout";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import ClearIcon from "@mui/icons-material/Clear";
import { useNavigate, Outlet } from "react-router-dom";
import { useGoogleLogin } from "@react-oauth/google";

const Navbar = (props) => {
  const {
    isAuthenticated,
    login,
    logout,
    toggleColorMode,
    // changeMessage,
    clearMessage,
    user,
    message,
    children,
    mode,
  } = props;
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [expand, setExpand] = useState(false);
  const [userLogOut, setUserLogOut] = useState(false);
  const [userLogIn, setUserLogIn] = useState(false);
  const navigate = useNavigate();
  const logoutUser = () => {
    logout();
    setUserLogOut(true);
  };

  const googleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      const res = await axios
        .post(`${process.env.REACT_APP_API_URL}/auth/token/`, {
          token: tokenResponse.access_token,
          grant_type: "convert_token",
        })
        .catch((err) => console.log("login Error", err));
      if (res && res.data) {
        login(res.data);
        setUserLogIn(true);
      }
    },
    onError: (error) => console.log("on error login", error),
  });

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const guestLinks = () => (
    <Controls.Button text="Login" onClick={googleLogin} />
  );

  const authLinks = () => (
    <>
      {/* <Grid container> */}
      <Typography>
        {user ? <>{`${user.first_name} ${user.last_name}`}</> : null}
      </Typography>
      <Controls.Button
        text={"Logout"}
        onClick={logoutUser}
        href="#!"
        endIcon={<LogoutIcon />}
      />
      {/* <Controls.Button text={"Refresh"} onClick={refreshToken} href="refresh" /> */}
      {/* </Grid> */}
    </>
  );

  const showDrawer = () => (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: drawerWidth,
          boxSizing: "border-box",
        },
      }}
      variant="persistent"
      anchor="left"
      open={open}
    >
      <DrawerHeader>
        <IconButton onClick={handleDrawerClose}>
          {theme.direction === "ltr" ? (
            <ChevronLeftIcon />
          ) : (
            <ChevronRightIcon />
          )}
        </IconButton>
      </DrawerHeader>
      <Divider />
      <List>
        {NavbarData.map((item, index) => {
          const { text, icon, link, nested } = item;
          return (
            <div key={"DrawerDiv" + index}>
              <ListItem
                button
                key={text + index}
                onClick={() => {
                  if (nested) setExpand(!expand);
                  return navigate(link, {
                    state: { item: props.item },
                  });
                }}
              >
                <ListItemIcon key={"DrawerIcon" + index}>{icon}</ListItemIcon>
                <ListItemText primary={text} key={"DrawerText" + index} />
                {nested ? (
                  expand ? (
                    <ExpandLess key={`expandLess${text}`} />
                  ) : (
                    <ExpandMore key={`expandMore${text}`} />
                  )
                ) : null}
              </ListItem>
              {nested ? (
                <Collapse key={`collapse ${text}`} in={expand} timeout="auto">
                  <List
                    key={`nestedList ${text}`}
                    component="div"
                    disablePadding
                  >
                    {nested.map((subItem, subIndex) => {
                      return (
                        <div key={`nestedListDiv ${subIndex}`}>
                          <ListItem
                            button
                            key={subItem.text + subIndex}
                            sx={{
                              paddingLeft: theme.spacing(3),
                            }}
                            onClick={() => {
                              return navigate(subItem.link, {
                                state: { item: props.item },
                              });
                            }}
                          >
                            <ListItemIcon
                              key={"SubDrawerIcon" + subItem.text + subIndex}
                            >
                              {subItem.icon}
                            </ListItemIcon>
                            <ListItemText
                              primary={subItem.text}
                              key={"SubDrawerText" + subItem.text + subIndex}
                            />
                          </ListItem>
                          {subIndex === nested.length - 1 ? null : <Divider />}
                        </div>
                      );
                    })}
                  </List>
                </Collapse>
              ) : null}
              <Divider key={"Divider" + index} />
            </div>
          );
        })}
      </List>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: 1,
          p: 3,
        }}
      >
        <IconButton sx={{ ml: 1 }} onClick={toggleColorMode} color="inherit">
          {mode === "dark" ? <Brightness7Icon /> : <Brightness4Icon />}
        </IconButton>
      </Box>
    </Drawer>
  );
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          {isAuthenticated ? (
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              sx={{ mr: 2, ...(open && { display: "none" }) }}
            >
              <MenuIcon />
            </IconButton>
          ) : null}
          <Grid container justifyContent="space-between" alignItems="center">
            <QWaveLogo />
            <Typography sx={{ color: message.color }}>
              {message.message}
            </Typography>
            {message.message ? (
              <IconButton
                onClick={() => {
                  clearMessage();
                }}
              >
                <ClearIcon />
              </IconButton>
            ) : null}
            <Hidden xsDown={open ? true : false}>
              {isAuthenticated ? authLinks() : guestLinks()}
            </Hidden>
          </Grid>
        </Toolbar>
      </AppBar>
      {isAuthenticated ? showDrawer() : null}
      <Main open={open}>
        <DrawerHeader />
        {isAuthenticated ? (
          <>
            <Outlet />
            {children}
          </>
        ) : (
          <Grid container justifyContent="center">
            <QWaveLogoBig />
          </Grid>
        )}
      </Main>
    </Box>
  );
};

// export default Navbar;
const mapStateToProps = (state) => ({
  // isAuthenticated: true,
  user: state.user,
  isAuthenticated: state.isAuthenticated,
});

export default connect(mapStateToProps, { login, logout })(Navbar);
